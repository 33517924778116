import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import {
	createImageCache,
	createVideoCache,
	isVideoCached,
	migrateVideoUrl,
} from "../../utils/videoCache";
import {
	getPlayerByPassword,
	listCategories,
	playerActivationStore,
	queryVideos,
} from "../../utils/firebase";
import type { IPlayer } from "../../entities/player.entity";
import type { IVideo } from "../../entities/video.entity";
import { captureException } from "@sentry/react";

type IVideoWithCache = IVideo & {
	migratedVideoUrl: string;
};

export const useCurrentPlayer = () => {
	return useQuery({
		queryKey: ["player", "current", playerActivationStore.activationPassword],
		queryFn: async () => {
			const playerPassword = playerActivationStore.activationPassword;

			if (!playerPassword) {
				return Promise.resolve(null);
			}

			return getPlayerByPassword(playerPassword);
		},
	});
};

export const startPreloadingEveryVideo = async ({
	onProgress,
	onComplete,
	onAlreadyCached,
	player,
}: {
	player: IPlayer;
	onProgress: (progress: {
		total: number;
		loadedVideos: IVideo[];
		loaded: number;
	}) => void;
	onComplete: () => void;
	onAlreadyCached: () => void;
}) => {
	console.log("startPreloadingEveryVideo", "player.groups", player.groups);

	const allCategories = await listCategories();

	for (const category of allCategories) {
		await createImageCache(category.bgImage?.url);
	}

	const allVideos: IVideoWithCache[] = (await queryVideos(player.groups)).map(
		(video) => ({
			...video,
			migratedVideoUrl: migrateVideoUrl(video.video.url),
		}),
	);

	const uncachedVideos: IVideoWithCache[] = [];
	const cachedVideos: IVideoWithCache[] = [];

	for (const video of allVideos) {
		if (await isVideoCached(video.migratedVideoUrl)) {
			await createImageCache(video.thumbnail?.url);
			cachedVideos.push(video);
			continue;
		}

		uncachedVideos.push(video);
	}

	const uncachedVideosCount = uncachedVideos.length;
	let cachedVideosCount = 0;

	onProgress({
		total: uncachedVideosCount,
		loadedVideos: cachedVideos,
		loaded: cachedVideosCount,
	});

	if (uncachedVideos.length === 0) {
		onAlreadyCached();
		return;
	}

	for (const video of uncachedVideos) {
		try {
			await createVideoCache(video.migratedVideoUrl);
			await createImageCache(video.thumbnail?.url);
			// await createImageCache(migrateThumbnailUrl(video.thumbnail?.url));
			cachedVideosCount++;

			onProgress({
				total: uncachedVideosCount,
				loaded: cachedVideosCount,
				loadedVideos: cachedVideos,
			});
		} catch (error) {
			captureException(error);
			console.error(error);
		}
	}

	onComplete();
};

export function useVideosPreload() {
	const { enqueueSnackbar } = useSnackbar();

	const [videosPreloading, setVideosPreloading] = useState({
		total: 0,
		loadedVideos: [] as IVideo[],
		loaded: 0,
	});

	const currentPlayer = useCurrentPlayer();

	const preload = useQuery({
		queryKey: ["videos", "preloading", currentPlayer.data?.id],
		enabled: Boolean(currentPlayer.data),
		async queryFn() {
			if (!window.api?.isInstalledPlayer) {
				return Promise.resolve(true);
			}

			await startPreloadingEveryVideo({
				player: currentPlayer.data!,
				onProgress: (progress) => {
					setVideosPreloading(progress);
				},
				onComplete: () => {
					enqueueSnackbar({
						message: "Vídeos carregados com sucesso!",
						variant: "success",
					});
				},
				onAlreadyCached: () => {
					console.log("videos already cached");
					enqueueSnackbar({
						message: "Vídeos já carregados!",
						variant: "success",
					});
				},
			});

			return Promise.resolve(true);
		},
	});

	return {
		isFetching: preload.isFetching,
		isFetched: preload.isFetched,
		total: videosPreloading.total,
		loaded: videosPreloading.loaded,
		loadedVideos: videosPreloading.loadedVideos,
	};
}
