import { captureException } from "@sentry/react";

export const fetchVideo = async (url: string) => {
	const response = await fetch(url);

	console.log("videos fetched with status", response.status);

	if (!response.ok) {
		console.error(response.body);
		throw new Error("Network response was not ok");
	}

	return await response.blob();
};

export const openIndexedDB = async (name: string, version: number) => {
	const indexedDdRequest = indexedDB.open(name, version);

	const promise: Promise<IDBDatabase> = new Promise((resolve, reject) => {
		indexedDdRequest.onupgradeneeded = () => {
			const db = indexedDdRequest.result;
			if (!db.objectStoreNames.contains("video")) {
				db.createObjectStore("video");
			}
		};

		indexedDdRequest.onsuccess = () => {
			const db = indexedDdRequest.result;
			resolve(db);
		};

		indexedDdRequest.onerror = () => {
			reject("error");
		};

		indexedDdRequest.onblocked = () => {
			reject("blocked");
		};
	});

	return promise;
};

const promisifyIDBRequest = (request: IDBRequest) => {
	return new Promise((resolve, reject) => {
		request.onsuccess = () => {
			resolve(request.result);
		};

		request.onerror = () => {
			captureException(request.error);
			reject(request.error);
		};
	});
};

export const openIndexedDBTransaction = async (
	name: string,
	version: number,
	mode: IDBTransactionMode,
) => {
	const db = await openIndexedDB("video", version);
	const transaction = db.transaction("video", mode);
	const store = transaction.objectStore("video");
	return store;
};

export const storeVideoInDb = async (blob: Blob, url: string) => {
	const transaction = await openIndexedDBTransaction(url, 1, "readwrite");
	const request = await promisifyIDBRequest(transaction.put(blob, url));
	return request;
};

export const getVideoFromDb = async (url: string) => {
	const transaction = await openIndexedDBTransaction(url, 1, "readonly");
	const request = await promisifyIDBRequest(transaction.get(url));
	return request as Blob;
};

export const getVideoBlob = async (url: string, fallbackUrl: string) => {
	const video = await getVideoFromDb(url);
	if (video) {
		console.log("video in cache", url);

		return video;
	}

	const fallbackVideo = await getVideoFromDb(fallbackUrl);
	if (fallbackVideo) {
		console.log("fallback video in cache", fallbackUrl);
		return fallbackVideo;
	}

	return await createVideoCache(url);
};

export const getVideoUrl = async (url: string, fallbackUrl: string) => {
	if (!window.api?.isInstalledPlayer) {
		return url || fallbackUrl;
	}

	const blob = await getVideoBlob(url, fallbackUrl);
	return URL.createObjectURL(blob);
};

export const isVideoCached = async (url: string) => {
	return Boolean(await getVideoFromDb(url));
};

export const createVideoCache = async (url: string) => {
	console.log("creating video cache", url);
	const blob = await fetchVideo(url);
	console.log("fetched video of size", blob.size);
	const storedVideo = await storeVideoInDb(blob, url);
	console.log("stored video", storedVideo);
	return blob;
};

export const migrateVideoUrl = (url: string) => {
	return [
		"https://firebasestorage.googleapis.com/v0/b/condorflix-dev.appspot.com/o/videos%2F",
		"https://megacontent-media.09c520dec22f7635ae87f8a6aae98255.r2.cloudflarestorage.com/condortv/video/",
	].reduce(
		(acc, curr) =>
			acc.replace(
				curr,
				"https://pub-c2e80913106a493bafe4bc588fd84f42.r2.dev/condortv/video/",
			),
		url,
	);
};

export const migrateThumbnailUrl = (url: string | undefined) => {
	if (!url) {
		return "";
	}

	return [
		"https://firebasestorage.googleapis.com/v0/b/condorflix-dev.appspot.com/o/thumbnails%2F",
		"https://megacontent-media.09c520dec22f7635ae87f8a6aae98255.r2.cloudflarestorage.com/condortv/thumbnail/",
	].reduce(
		(acc, curr) =>
			acc.replace(
				curr,
				"https://pub-c2e80913106a493bafe4bc588fd84f42.r2.dev/condortv/thumbnail/",
			),
		url,
	);
};

// this function create a cache for an image
// using the <link rel="preload"> tag
export const createImageCache = async (url: string) => {
	console.log("creating image cache", url);
	const image = new Image();
	image.src = url;
	image.crossOrigin = "anonymous";
	image.style.display = "none";
	document.body.appendChild(image);
	await new Promise((resolve, reject) => {
		image.onload = () => {
			resolve(true);
		};
		image.onerror = () => {
			reject();
		};
	});
	console.log("image cached", url);
	return image;
};
